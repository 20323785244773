export const FETCH_PATCH_MANIFEST = "FETCH_PATCH_MANIFEST";
export const FETCH_PATCH_MANIFEST_SUCCESS = "FETCH_PATCH_MANIFEST_SUCCESS";
export const FETCH_PATCH_MANIFEST_FAILURE = "FETCH_PATCH_MANIFEST_FAILURE";

export function fetchPageManifest(pageId) {
  return {
    type: FETCH_PATCH_MANIFEST,
    pageId
  };
}

export function fetchPageManifestSuccess(pageId, manifest) {
  return {
    type: FETCH_PATCH_MANIFEST_SUCCESS,
    pageId,
    manifest
  };
}

export function fetchPageManifestFailure(pageId, error) {
  return {
    type: FETCH_PATCH_MANIFEST_FAILURE,
    pageId,
    error
  };
}
