import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
// import TopbarNavDashboards from './TopbarNavDashboards';
// import TopbarNavUIElements from './TopbarNavUIElements';
// import TopbarNavOtherPages from './TopbarNavOtherPages';

const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true';

const TopbarNav = ({ t }) => (
  /* <nav className="topbar__nav">
    <Link className="topbar__nav-link" to="/home">Home</Link>
    <Link className="topbar__nav-link" to="/about-us">About Us</Link>
    <Link className="topbar__nav-link" to="/contact">Contact Us</Link>
  </nav> */
  <nav className="topbar__menu-nav">
    <Link to="/home">
      <button>
        {t('footer.home')}
      </button>
    </Link>
    {/* {!isMaintenance &&
      <Link to="/beanbb_download20211216">
        <button>
          {t('footer.product_download')}
        </button>
      </Link>} */}
    {!isMaintenance && <Link to="/about-us">
      <button>
        {t('footer.aboutus')}
      </button>
    </Link>}
  </nav>
);

TopbarNav.propTypes = {
  t: PropTypes.func.isRequired,
};

export default (withTranslation('common')(TopbarNav));
