import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import MainWrapper from "./MainWrapper";
import Layout from "../Layout";
// import VaccineEvent from "../Events/Vaccine";

const Home = lazy(() => import("../Home"));
// const LearnMore = lazy(() => import("../LearnMore"));
const Copyright = lazy(() => import("../Copyright"));
const PrivacyPolicy = lazy(() => import("../PrivacyPolicy"));
const LegalStatements = lazy(() => import("../LegalStatements"));
const DynamicPage = lazy(() => import("../Page/DynamicPage"));

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route exact path="/home" component={Home} />
        
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/copyright" component={Copyright} />
        <Route exact path="/legalstatements" component={LegalStatements} />
        
        <Route exact path="/:pageId+" component={DynamicPage} />
        <Route path="" render={() => <Redirect exact to="/home" />} />
      </Switch>
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Suspense fallback={<div />}>
        <Switch>
          {/* pages from above don't show topbar, otherwise wrap routes */}
          <Route path="/" component={wrappedRoutes} />
        </Switch>
      </Suspense>
    </main>
  </MainWrapper>
);

export default Router;
