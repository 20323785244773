import resources from "./resources";

export const config = {
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false } // React already does escaping
};

export { resources };
