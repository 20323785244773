import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import axios from "axios";

import { setRegion } from "../../redux/actions/settings/regionActions";

const regions = {
  AR: {
    value: "AR",
    label: "Argentina",
    image: `${process.env.PUBLIC_URL}/img/regions/region_AR.png`,
    language: "en"
  },
  AU: {
    value: "AU",
    label: "Australia",
    image: `${process.env.PUBLIC_URL}/img/regions/region_AU.png`,
    language: "en"
  },
  BR: {
    value: "BR",
    label: "Brazil",
    image: `${process.env.PUBLIC_URL}/img/regions/region_BR.png`,
    language: "en"
  },
  CA: {
    value: "CA",
    label: "Canada",
    image: `${process.env.PUBLIC_URL}/img/regions/region_CA.png`,
    language: "en"
  },
  CN: {
    value: "CN",
    label: "China",
    image: `${process.env.PUBLIC_URL}/img/regions/region_CN.png`,
    language: "zh-Hans"
  },
  FI: {
    value: "FI",
    label: "Finland",
    image: `${process.env.PUBLIC_URL}/img/regions/region_FI.png`,
    language: "en"
  },
  FR: {
    value: "FR",
    label: "France",
    image: `${process.env.PUBLIC_URL}/img/regions/region_FR.png`,
    language: "en"
  },
  DE: {
    value: "DE",
    label: "Germany",
    image: `${process.env.PUBLIC_URL}/img/regions/region_DE.png`,
    language: "en"
  },
  HK: {
    value: "HK",
    label: "Hong Kong",
    image: `${process.env.PUBLIC_URL}/img/regions/region_HK.png`,
    language: "zh-Hant"
  },
  IN: {
    value: "IN",
    label: "India",
    image: `${process.env.PUBLIC_URL}/img/regions/region_IN.png`,
    language: "en"
  },
  ID: {
    value: "ID",
    label: "Indonesia",
    image: `${process.env.PUBLIC_URL}/img/regions/region_ID.png`,
    language: "en"
  },
  IE: {
    value: "IE",
    label: "Ireland",
    image: `${process.env.PUBLIC_URL}/img/regions/region_IE.png`,
    language: "en"
  },
  IT: {
    value: "IT",
    label: "Italy",
    image: `${process.env.PUBLIC_URL}/img/regions/region_IT.png`,
    language: "en"
  },
  JP: {
    value: "JP",
    label: "Japan",
    image: `${process.env.PUBLIC_URL}/img/regions/region_JP.png`,
    language: "ja"
  },
  MO: {
    value: "MO",
    label: "Macau",
    image: `${process.env.PUBLIC_URL}/img/regions/region_MO.png`,
    language: "zh-Hant"
  },
  MY: {
    value: "MY",
    label: "Malaysia",
    image: `${process.env.PUBLIC_URL}/img/regions/region_MY.png`,
    language: "en"
  },
  MX: {
    value: "MX",
    label: "Mexico",
    image: `${process.env.PUBLIC_URL}/img/regions/region_MX.png`,
    language: "en"
  },
  NL: {
    value: "NL",
    label: "Netherlands",
    image: `${process.env.PUBLIC_URL}/img/regions/region_NL.png`,
    language: "en"
  },
  NZ: {
    value: "NZ",
    label: "New Zealand",
    image: `${process.env.PUBLIC_URL}/img/regions/region_NZ.png`,
    language: "en"
  },
  PH: {
    value: "PH",
    label: "Philippines",
    image: `${process.env.PUBLIC_URL}/img/regions/region_PH.png`,
    language: "en"
  },
  PL: {
    value: "PL",
    label: "Poland",
    image: `${process.env.PUBLIC_URL}/img/regions/region_PL.png`,
    language: "en"
  },
  PT: {
    value: "PT",
    label: "Portugal",
    image: `${process.env.PUBLIC_URL}/img/regions/region_PT.png`,
    language: "en"
  },
  RO: {
    value: "RO",
    label: "Romania",
    image: `${process.env.PUBLIC_URL}/img/regions/region_RO.png`,
    language: "en"
  },
  RU: {
    value: "RU",
    label: "Russia",
    image: `${process.env.PUBLIC_URL}/img/regions/region_RU.png`,
    language: "en"
  },
  SA: {
    value: "SA",
    label: "Saudi Arabia",
    image: `${process.env.PUBLIC_URL}/img/regions/region_SA.png`,
    language: "en"
  },
  SG: {
    value: "SG",
    label: "Singapore",
    image: `${process.env.PUBLIC_URL}/img/regions/region_SG.png`,
    language: "en"
  },
  ZA: {
    value: "ZA",
    label: "South Africa",
    image: `${process.env.PUBLIC_URL}/img/regions/region_ZA.png`,
    language: "en"
  },
  KR: {
    value: "KR",
    label: "South Korea",
    image: `${process.env.PUBLIC_URL}/img/regions/region_KR.png`,
    language: "en"
  },
  ES: {
    value: "ES",
    label: "Spain",
    image: `${process.env.PUBLIC_URL}/img/regions/region_ES.png`,
    language: "en"
  },
  SE: {
    value: "SE",
    label: "Sweden",
    image: `${process.env.PUBLIC_URL}/img/regions/region_SE.png`,
    language: "en"
  },
  CH: {
    value: "CH",
    label: "Switzerland",
    image: `${process.env.PUBLIC_URL}/img/regions/region_CH.png`,
    language: "en"
  },
  TW: {
    value: "TW",
    label: "Taiwan",
    image: `${process.env.PUBLIC_URL}/img/regions/region_TW.png`,
    language: "zh-Hant"
  },
  TH: {
    value: "TH",
    label: "Thailand",
    image: `${process.env.PUBLIC_URL}/img/regions/region_TH.png`,
    language: "en"
  },
  TR: {
    value: "TR",
    label: "Turkey",
    image: `${process.env.PUBLIC_URL}/img/regions/region_TR.png`,
    language: "en"
  },
  UA: {
    value: "UA",
    label: "Ukraine",
    image: `${process.env.PUBLIC_URL}/img/regions/region_UA.png`,
    language: "en"
  },
  AE: {
    value: "AE",
    label: "United Arab Emirates",
    image: `${process.env.PUBLIC_URL}/img/regions/region_AE.png`,
    language: "en"
  },
  GB: {
    value: "GB",
    label: "United Kingdom",
    image: `${process.env.PUBLIC_URL}/img/regions/region_GB.png`,
    language: "en"
  },
  US: {
    value: "US",
    label: "United States",
    image: `${process.env.PUBLIC_URL}/img/regions/region_US.png`,
    language: "en"
  }
};

const languages = {
  en: {
    value: "en",
    label: "English (US)",
    image: `${process.env.PUBLIC_URL}/img/regions/region_US.png`
  },
  ja: {
    value: "ja",
    label: "日本語",
    image: `${process.env.PUBLIC_URL}/img/regions/region_JP.png`
  },
  "zh-Hans": {
    value: "zh-Hans",
    label: "简体中文",
    image: `${process.env.PUBLIC_URL}/img/regions/region_CN.png`
  },
  "zh-Hant": {
    value: "zh-Hant",
    label: "繁體中文",
    image: `${process.env.PUBLIC_URL}/img/regions/region_HK.png`
  }
};

export function withLocalization(WrappedComponent) {
  const WithLocalization = props => {
    const { region, onRegionChange, i18n } = props;

    const handleRegionChange = r => {
      if (regions[r]) {
        onRegionChange(r);
      } else {
        onRegionChange(null);
      }
    };
    const handleLanguageChange = language => {
      i18n.changeLanguage(language);
    };

    return (
      <WrappedComponent
        {...props}
        regions={regions}
        languages={languages}
        region={region}
        language={i18n.language}
        onRegionChange={handleRegionChange}
        onLanguageChange={handleLanguageChange}
      />
    );
  };

  return compose(
    withTranslation("common"),
    connect(
      ({ settings: { region } = {} }) => ({ region }),
      dispatch => ({
        onRegionChange: region => {
          dispatch(setRegion(region));
        }
      })
    )
  )(WithLocalization);
}

const Initializer = ({ region, onRegionChange, i18n }) => {
  useEffect(() => {
    if (!region) {
      onRegionChange("loading", "");
      axios
        .get("https://ipapi.co/json")
        .then(res => {
          const { country } = res.data || {};
          onRegionChange(country, "loading");
        })
        .catch(() => {
          onRegionChange("US", "loading");
        });
    }
  }, [region, onRegionChange]);

  useEffect(() => {
    if (regions[region]) {
      i18n.changeLanguage(regions[region].language);
    }
  }, [region, i18n]);

  return null;
};

export let LocalizationInitializer = compose(
  withTranslation("common"),
  connect(
    ({ settings: { region } = {} }) => ({ region }),
    dispatch => ({
      onRegionChange: (region, prevRegion) => {
        dispatch(setRegion(region, prevRegion));
      }
    })
  )
)(Initializer);
