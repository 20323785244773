// reducer composition for reducers related to ui
import { combineReducers } from "redux";

import customizer from "./ui/customizerReducer";
import sidebar from "./ui/sidebarReducer";
import theme from "./ui/themeReducer";

export default combineReducers({
  customizer,
  sidebar,
  theme
});
