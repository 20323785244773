import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import uiReducer from '../../redux/reducers/uiReducer';
import settingsReducer from "../../redux/reducers/settingsReducer";
import pageReducer from "../../redux/reducers/pageReducer";

const logger = createLogger({
  diff: false,
  predicate: undefined, // (state, action) => { return action.type.startsWith('filtering_keyword'); }
});

const middleware = [
  thunk,
  process.env.NODE_ENV === 'development' && logger,
].filter(Boolean);

const persistConfig = {
  key: 'root',
  whitelist: ['session'],
  storage,
};

const rootReducer = combineReducers({
  form: formReducer,
  ui: uiReducer,
  settings: settingsReducer,
  page: pageReducer,
});

export const store = createStore(persistReducer(persistConfig, rootReducer), applyMiddleware(...middleware));

export const persistor = persistStore(store);
