import { combineReducers } from "redux";

import { SET_REGION } from "../actions/settings/regionActions";

function region(state = "", action) {
  switch (action.type) {
    case SET_REGION:
      return action.prevRegion === undefined || action.prevRegion === state
        ? action.region
        : state;

    default:
      return state;
  }
}

export default combineReducers({ region });
